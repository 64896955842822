figure, figcaption {
	display: block;
}

.transition {
    -webkit-transition: top 400ms linear;
    -moz-transition: top 400ms linear;
    -ms-transition: top 400ms linear;
    -o-transition: top 400ms linear;
    transition: top 400ms linear;
}

.timeTo {
	font-family: Tahoma, Verdana, Aial, sans-serif;
	font-size: 28px;
	line-height: 108%;
	font-weight: bold;
	height: 32px;
}

.timeTo span {
	vertical-align: top;
}

.timeTo.timeTo-white div {
	color: black;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 38%, #cccccc 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(38%,#ffffff), color-stop(100%,#cccccc)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 38%,#cccccc 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 38%,#cccccc 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 38%,#cccccc 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 38%,#cccccc 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}
.timeTo.timeTo-black div {
	color: white;
    background: #45484d; /* Old browsers */
    background: -moz-linear-gradient(top, #45484d 0%, #000000 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#45484d), color-stop(100%,#000000)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #45484d 0%,#000000 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #45484d 0%,#000000 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #45484d 0%,#000000 100%); /* IE10+ */
    background: linear-gradient(to bottom, #45484d 0%,#000000 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.timeTo.timeTo-black .timeTo-alert {
    background: #a74444; /* Old browsers */
    background: -moz-linear-gradient(top, #a74444 0%, #3f0000 67%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#a74444), color-stop(67%,#3f0000)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #a74444 0%,#3f0000 67%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #a74444 0%,#3f0000 67%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #a74444 0%,#3f0000 67%); /* IE10+ */
    background: linear-gradient(to bottom, #a74444 0%,#3f0000 67%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a74444', endColorstr='#3f0000',GradientType=0 ); /* IE6-9 */
}

.timeTo.timeTo-white .timeTo-alert {
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 35%, #e17373 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(35%,#ffffff), color-stop(100%,#e17373)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 35%,#e17373 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 35%,#e17373 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 35%,#e17373 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 35%,#e17373 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e17373',GradientType=0 ); /* IE6-9 */
}

.timeTo figure {
	display: inline-block;
	margin: 0;
	padding: 0;
}
.timeTo figcaption {
	text-align: center;
	/*font-size: 12px;*/
	line-height: 80%;
	font-weight: normal;
	color: #888;
}

.timeTo div {
	position: relative;
	display: inline-block;
	width: 25px;
	height: 30px;
	border-top: 1px solid silver;
	border-right: 1px solid silver;
	border-bottom: 1px solid silver;
	overflow: hidden;
}
.timeTo div.first {
	border-left: 1px solid silver;
}

.timeTo ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: absolute;
	left: 3px;
}

.timeTo ul li {
    margin: 0;
    padding: 0;
    list-style: none;
}
